<template>
  <v-container id="accounts" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="custom-card-cont">
              <v-card class="card-cont">
                <v-row class="account-search-bar">
                  <v-col cols="12" class="text-field-cont">
                    <v-text-field
                      v-model="search"
                      label="Company Name"
                      @input="debounceSearch"
                    />
                    <v-select
                      v-model="accountFilter"
                      :items="accountFilterOptions"
                      label="Filter accounts"
                    ></v-select>
                    <v-select
                      v-model="verificationFilter"
                      :items="verificationFilterOptions"
                      label="Filter Verification"
                    ></v-select>
                    <v-btn
                      class="ma-2 mt-3 color_class"
                      dark
                      outlined
                      id="clear-filters"
                      :disabled="loading"
                      @click="clearFilters"
                    >
                      Clear filters
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </md-card-content>
          </form>
          <v-row class="header-container">
            <v-col cols="2" class="text-element-2">Company Name</v-col>
            <v-col cols="2" class="text-element-2">Type</v-col>
            <v-col cols="2" class="text-element-2">Name</v-col>
            <v-col cols="2" class="text-element-2">Email</v-col>
            <v-col cols="2">Joined on</v-col>
            <v-col cols="1">Public Profile</v-col>
            <v-col cols="1" class="text-element-5">Verified Status</v-col>
          </v-row>
          <div v-if="accounts.length">
            <v-card
              class="card-cont"
              v-for="account in accounts"
              :key="account.uuid"
            >
              <v-row class="account-container">
                <v-col cols="2" class="text-element">
                  <span>{{ account.organization_registry.name }}</span>
                </v-col>
                <v-col cols="2" class="text-element">
                  <span>{{
                    account.organization_registry.type_details.name
                  }}</span>
                </v-col>
                <v-col cols="2" class="text-element">
                  <span>{{ account.users[0].full_name }}</span>
                </v-col>
                <v-col cols="2" class="text-element">
                  <span>{{ account.users[0].email }}</span>
                </v-col>
                <v-col cols="2">
                  <span>{{
                    new Date(account.created_at).toLocaleString()
                  }}</span>
                </v-col>
                <v-col cols="1" class="text-element">
                  <a
                    :href="`/#/app/company/${account.organization_registry.uuid}`"
                    target="_blank"
                  >
                    <v-icon color="#442d65">mdi-eye</v-icon>
                  </a>
                </v-col>
                <v-col cols="1" class="text-element-btn">
                  <div @click="toggleVerificationStatus(account)" class="ml-12">
                    <v-switch
                      v-model="account.verified"
                      :loading="loading"
                      :disabled="loading"
                    ></v-switch>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <v-card v-else class="card-cont"> No accounts Available </v-card>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="accounts.length"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Pagination from "../../components/Pagination";
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import debounce from "lodash/debounce";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      selected: [],
      search: "",
      accountFilter: null,
      verificationFilter: null,
      loading: false,
      accounts: [],
      selectedHeader: "",
      accountFilterOptions: [
        { text: "Brand", value: "brand" },
        { text: "Supplier", value: "supplier" },
      ],
      verificationFilterOptions: [
        { text: "Verified", value: 1 },
        { text: "Unverified", value: 0 },
      ],
    };
  },
  async mounted() {
    await this.fetchAccounts();
  },
  methods: {
    async fetchAccounts() {
      this.loading = true;

      let query = `?page=${this.currentPage}`;

      if (this.accountFilter) {
        query += `&organization_type=${this.accountFilter}`;
      }

      if (this.verificationFilter !== null) {
        query += `&verified_status=${this.verificationFilter}`;
      }

      if (this.search) {
        query += `&query=${this.search}`;
      }

      const response = await restAdapter.get("/api/accounts" + query);

      if (response.data) {
        this.accounts = response.data.data;
        this.totalCount = response.data.total;
      } else {
        this.selected = [];
        this.accounts = [];
      }
      this.loading = false;
    },
    debounceSearch: debounce(function () {
      this.currentPage = 1;
      this.fetchAccounts();
    }, 200),
    onFiltersChange() {
      this.currentPage = 1;
      this.fetchAccounts();
    },
    loadPage(page) {
      this.currentPage = page;
      this.fetchAccounts();
    },
    toggleVerificationStatus(account) {
      const newStatus = account.verified;
      restAdapter
        .put(`/api/accounts/${account.uuid}/change_status`, {
          status: newStatus,
        })
        .then((res) => {
          const successMessage = newStatus ? "verified" : "unverified";
          notification.success(`Account ${successMessage}`);
          account.verified = newStatus;
        });
    },
    clearFilters() {
      this.accountFilter = null;
      this.verificationFilter = null;
      this.search = "";
      this.fetchAccounts();
    },
  },
  watch: {
    accountFilter: "onFiltersChange",
    verificationFilter: "onFiltersChange",
  },
};
</script>

<style>
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}
.verification-btn {
  width: 100px;
}
.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.account-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.account-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.account-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
</style>
